import request from "../utils/request";

export function get_store_product_list(data: JSON){
    return request({
        url: "/getStoreProductList",
        method: "post",
        data: data
    });
}

export function get_store_product_detail(data: JSON){
    return request({
        url: "/getStoreProductDetail",
        method: "post",
        data: data
    });
}

export function save_store_product_detail(data: FormData){
    return request({
        url: "/saveStoreProductDetail",
        method: "post",
        data: data
    });
}

export function delete_store_product(data: JSON) {
    return request({
        url: "/deleteStoreProduct",
        method: "post",
        data: data
    });
}